import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <main id="main" className="site-main dft-padding" role="main">
        <div className="wp-block-columns" id="fourohfour-row">
          <div className="wp-block-column">
            <StaticImage
              height={450}
              width={408}
              loading="lazy"
              placeholder="tracedSVG"
              src="https://cdn.centerforinquiry.org/wp-content/uploads/sites/30/2019/02/22164457/poi-404-radio.png"
              alt=""
            />
          </div>
          <div className="wp-block-column">
            <h3 className="four-header blue-text">404</h3>
            <h2 className="blue-text">How'd You Get Here??</h2>
            <p className="blue-text">
              Let's take you back to where you belong.
            </p>
            <div className="wp-block-buttons">
              <div className="wp-block-button drop-shadow">
                <a
                  href="/"
                  className="wp-block-button__link has-white-color has-poi-gold-background-color has-text-color has-background"
                >
                  Back Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
